import React from "react";
import Base from "components/airtable";
import { useDropzone } from 'react-dropzone';
import { storage } from "components/firebase";
import Box from "common/components/Box";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import Badge from '@mui/material/Badge';
import FileUploadRounded from '@mui/icons-material/FileUploadRounded';
const DropArea = styled.div`
    padding: 5px;
`;

const DropAreaLabel = styled.p`
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const DropAreaInsutructions = styled.p`
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;

`;

function DocumentUpload({
    customerid,
    airtableField,
    storageDirectory,
    label,
    setProgress,
    initFileCount }) {

    const [fileCount, setFileCount] = React.useState(initFileCount);

    const uploadFiles = (acceptedFiles) => {
        console.log("in uploadpayroll journals");
        console.log(acceptedFiles);
        let rootRef = storage.ref();
        let urlList = [];
        acceptedFiles.forEach((file) => {
            let uploadTask = rootRef.child(
                `customers/${customerid}/${storageDirectory}/${file.name}`).put(file);

            console.log("putting file in storage");

            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress({
                        message: `Uploading file ${file.name}`,
                        progress: progress,
                        show: true
                    });
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    console.error(error);
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...

                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        urlList.push({
                            url: downloadURL
                        });
                        setProgress({
                            message: `Updating database...`,
                            progress: 15.00,
                            show: true
                        });

                        // If we have all the files uploaded we update airtable
                        if (urlList.length === acceptedFiles.length) {

                            Base("Questionaire").find(customerid, function (err, record) {
                                if (err) {
                                    console.error(err);
                                    return;
                                }

                                Base("Questionaire").update([
                                    {
                                        id: customerid,
                                        fields: {
                                            [`${airtableField}`]: [
                                                ...urlList,
                                                ...(record.fields[`${airtableField}`] ?
                                                    record.fields[`${airtableField}`]
                                                    :
                                                    [])
                                            ]
                                        }
                                    }
                                ], (err, records) => {
                                    console.log(records);
                                    if (err) {
                                        console.error(err);
                                        return;
                                    }
                                    setProgress({
                                        message: "Database update complete",
                                        progress: 100.00,
                                        show: false
                                    });
                                    records[0].fields[`${airtableField}`] &&
                                        setFileCount(records[0].fields[`${airtableField}`].length)
                                    console.log("Updated: ", records)
                                })
                            });
                        }

                    });
                }
            );
        });

    }

    const onDrop = React.useCallback((acceptedFiles) => {
        console.log(`${label}`, customerid);
        console.log(acceptedFiles);
        uploadFiles(acceptedFiles);
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });


    return (
        <Paper sx={{
            width: "200px",
            margin: "10px",
            background: "#ECEFF1",
            cursor: "pointer"
        }} elevation={3}>
            <Badge badgeContent={fileCount} color={fileCount !== 0 ? "success" : "error"}>
                <FileUploadRounded color="action" />
            </Badge>
            <DropArea {...getRootProps()}>
                <input {...getInputProps()} />
                <DropAreaLabel>{label}</DropAreaLabel>
                <DropAreaInsutructions>Drag and Drop or Click to Upload Files</DropAreaInsutructions>
            </DropArea>
        </Paper>
    )
}

export default DocumentUpload;