import * as React from "react";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import CustomerPortal from "containers/CustomerPortal";

const CustomerPage = ({ location }) => {

    return (
        <Layout showContactMap={false} location={location.pathname} showFreeConsult={false}>
            <Seo title="Customer Portal" />
            <CustomerPortal/>
        </Layout>
    );
};

export default CustomerPage;