import * as React from "react";
import firebase from "components/firebase";
import SignInScreen from "containers/Login";
import base from "components/airtable";
import { Card, CardActions } from "@mui/material";
import { CardActionArea } from "@mui/material";
import { CardContent } from "@mui/material";
import { CardHeader } from "@mui/material";
import Button from "common/components/Button";
import HeroAlt from "containers/HeroAlt";
import DocumentUpload from "./DocumentUpload";
import Paper from "@mui/material/Paper";
import Box from "common/components/Box";
import styled, { css } from 'styled-components';
import LinearProgressIndicator from "./LinearProgressIndicator";
import Text from "common/components/Text";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import humanParser from "humanparser";

const SectionTitle = styled.h6`
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-block: 0px;
    padding-block: 0px;
`;

const SectionInstructions = styled.div`
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px;
    max-width: 800px;
`;

const DocumentationArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;

const TopSection = styled.div`

    @media screen and (max-width: 1600px) {
        margin-top: -75px;
    }
    @media screen and (max-width: 1220px) {
        margin-top: -75px;
    }
    @media screen and (max-width: 990px) {
        width: 100%;
        margin-top: -50px;
    }
    @media screen and (max-width: 600px) {
        margin-top: -35px;
    }
`;

const StatusIndicatorBox = styled.div`
    border: 1px solid #ECEFF1;
    padding: 10px;
    margin: auto;
    border-radius: 10px;
    max-width: 50%;
`;

const AdminPortal = ({ location }) => {

    const [user, setUser] = React.useState(null);
    const [isAdmin, setIsAdmin] = React.useState(true);
    const [customers, setCustomers] = React.useState([]);
    const [firstName, setFirstName] = React.useState('');
    const [customer, setCustomer] = React.useState();

    //const [customer, setCustomer] = React.useState({});
    const [progress, setProgress] = React.useState({
        visible: false,
        message: '',
        progress: 0.0
    });
    const [fileCounts, setFileCounts] = React.useState({
        firstPPPLoan: 0,
        secondPPPLoan: 0,
        payrollJournals: 0,
        pandl: 0,
        original941Returns: 0,
        otherDocumentation: 0
    });

    const clearSessionData = () => {
        setCustomers([]);
        setProgress({
            visible: false,
            message: '',
            progress: 0.0
        });
        setUser(null);
    }

    const renderAgreementStatus = (c) => {
        switch (c["Agreement Status"]) {
            case "Completed":
                return <Chip sx={{ margin: "5px" }} label={`Agreement Status: ${c["Agreement Status"]}`}
                    color="success" />
            default:
                return <Chip sx={{ margin: "5px" }} label={`Agreement Status: ${c["Agreement Status"]}`} color="primary" />
        }
    };

    const renderERTCPackageStatus = (c) => {
        switch (c["Agreement Status"]) {
            case "Delivered":
                return <Chip sx={{ margin: "5px" }} label={`ERTC Package Status: ${c["ERTC Package Status"]}`}
                    color="success" />
            default:
                return <Chip sx={{ margin: "5px" }} label={`ERTC Package Status: ${c["ERTC Package Status"]}`} color="primary" />
        }
    };

    const loadCustomerData = (user) => {

        base('Customer Portal Access').select({
            // selecting the 1 record with email = loggedin email
            view: "Grid view",
            filterByFormula: `{Email} = '${user.email}'`
        }).firstPage(function (err, records) {
            if (err) {
                console.error(err);
                return;
            }

            console.log(records);
            records[0].fields["Customer Link"].forEach(async (element) => {
                console.log("looking for customer: ", element)
                await base('Questionaire').find(element, (err, record) => {
                    if (err) {
                        console.error(err);
                    }
                    let c = { ...record.fields, id: record.id };
                    console.log("found customer: ", c);
                    setCustomer(c);
                    setCustomers((customers) => ([...customers, c]));
                });
            });
        });
    }

    React.useEffect(() => {
        setCustomers([]);

        const unregisterAuthObserver =
            firebase.auth().onAuthStateChanged(user => {
                setUser(user);
                if (user) {
                    // parse the first and last name
                    const human = humanParser.parseName(user.displayName);
                    setFirstName(human.firstName);

                    loadCustomerData(user);
                }
                else {
                    clearSessionData();
                }
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);


    return (
        <>
            {!firebase.auth().currentUser ? <HeroAlt title={"Administrator Portal"}
                text={`Welcome to the Customer Portal`} />
                :
                <HeroAlt title={`Hello ${firstName}`}
                    text={`Welcome to your Customer Portal.`} />}
            {!firebase.auth().currentUser ?
                <SignInScreen />
                :
                <TopSection>
                    {customer &&

                        <Paper sx={{
                            margin: "auto",
                            maxWidth: "1000px",
                            marginBottom: "10px"
                        }} elevation={4}>
                            <Card sx={{ margin: "auto" }} variant="outlined">
                                <CardHeader title={customer["Business Name"]}
                                    titleTypographyProps={{
                                        fontFamily: "Poppins",
                                        fontSize: "35px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "35px",
                                        letterSpacing: "-0.03em",
                                        textAlign: "left",
                                        margin: "5px",
                                        padding: "5px"

                                    }} />
                                <CardContent sx={{ marginLeft: "20px", padding: "5px" }}>
                                    <SectionTitle>Current Status</SectionTitle>
                                    <Card elevation={0}>
                                        <CardContent>
                                            <Stack stackspacing={2} direction="row">
                                                {renderAgreementStatus(customer)}
                                                {renderERTCPackageStatus(customer)}
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                    <SectionTitle>Company Information</SectionTitle>
                                    <Stack stackspacing={2} direction="row" sx={{ margin: "10px" }}>
                                        <Card variant="outlined" elevation={0} sx={{
                                            width: "50%",
                                            margin: "10px"
                                        }}>
                                            <CardHeader title="General" titleTypographyProps={{
                                                fontSize: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 400
                                            }} />
                                            <CardContent>
                                                <Text content={customer["Address Line 1"]}
                                                    fontFamily={'Poppins'}
                                                    fontWeight={500} />
                                                {customer["Address Line 2"] && <Text content={customer["Address Line 2"]}
                                                    fontFamily={'Poppins'}
                                                    fontWeight={500} />
                                                }
                                                {<Text content={customer["City"] + "," + customer["State"]}
                                                    fontFamily={'Poppins'}
                                                    fontWeight={500} />
                                                }
                                                {<Text content={customer["Zip"]}
                                                    fontFamily={'Poppins'}
                                                    fontWeight={500} />
                                                }
                                                {<Text content={customer["Phone"]}
                                                    fontFamily={'Poppins'}
                                                    fontWeight={500} />
                                                }
                                            </CardContent>
                                        </Card>
                                        <Card variant="outlined" elevation={0} sx={{
                                            width: "50%",
                                            margin: "10px"
                                        }}>
                                            <CardHeader title="Primary Contact" titleTypographyProps={{
                                                fontSize: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 400
                                            }} />
                                            <CardContent>
                                                <Text content={`${customer["First Name"]} ${customer["Last Name"]}`}
                                                    fontFamily={'Poppins'}
                                                    fontWeight={500} />
                                                <Text content={customer["Email"]}
                                                    fontFamily={'Poppins'}
                                                    fontWeight={500} />
                                                <Text content={customer["Role"]}
                                                    fontFamily={'Poppins'}
                                                    fontWeight={500} />
                                                <Text content={customer["Phone"]}
                                                    fontFamily={'Poppins'}
                                                    fontWeight={500} />

                                            </CardContent>
                                        </Card>
                                    </Stack>
                                    <SectionTitle>Documentation</SectionTitle>
                                    <SectionInstructions>
                                        <p>The tiles below turn green and provide you with an indicator as you
                                            upload documentation.</p>
                                        <p>For your convenice, here is a list of the documents we
                                            are reqesting: <b><a
                                                target="ert-credit-docs"
                                                href="https://firebasestorage.googleapis.com/v0/b/ertc-website-v2-a2.appspot.com/o/downloads%2FERC_Document_Request.pdf?alt=media&amp;token=c95b8655-6554-4201-964e-285c1cc86daa">Required Documents.</a></b></p>
                                    </SectionInstructions>
                                    {progress.show &&
                                        <StatusIndicatorBox>
                                            <Text content={progress.message}
                                                fontFamily={'Poppins'}
                                                fontWeight={500} />
                                            <LinearProgressIndicator value={progress.progress} />
                                        </StatusIndicatorBox>
                                    }
                                    <DocumentationArea>
                                        <DocumentUpload key={1} customerid={customer.id}
                                            airtableField={"First Forgiveness Application Attachment"}
                                            storageDirectory={"ForgivenessApplications"}
                                            label={"1st Forgiveness Application"}
                                            setProgress={setProgress}
                                            initFileCount={
                                                customer["First Forgiveness Application Attachment"] ?
                                                    customer["First Forgiveness Application Attachment"].length :
                                                    0}
                                        />
                                        <DocumentUpload key={2} customerid={customer.id}
                                            airtableField={"Second Forgiveness Application Attachment"}
                                            storageDirectory={"ForgivenessApplications"}
                                            label={"2nd Forgiveness Application"}
                                            setProgress={setProgress}
                                            countName={"secondPPPLoan"}
                                            fileCounts={fileCounts}
                                            setFileCounts={setFileCounts}
                                            initFileCount={
                                                customer["Second Forgiveness Application Attachment"] ?
                                                    customer["Second Forgiveness Application Attachment"].length :
                                                    0}
                                        />
                                        <DocumentUpload key={3} customerid={customer.id}
                                            airtableField={"Payroll Journals"}
                                            storageDirectory={"PayrollJournals"}
                                            label={"Payroll Journals"}
                                            setProgress={setProgress}
                                            countName={"payrollJournals"}
                                            fileCounts={fileCounts}
                                            setFileCounts={setFileCounts}
                                            initFileCount={
                                                customer["Payroll Journals"] ?
                                                    customer["Payroll Journals"].length :
                                                    0}
                                        />
                                        <DocumentUpload key={4} customerid={customer.id}
                                            airtableField={"P and L"}
                                            storageDirectory={"PandL"}
                                            label={"P & L"}
                                            setProgress={setProgress}
                                            countName={"pandl"}
                                            fileCounts={fileCounts}
                                            setFileCounts={setFileCounts}
                                            initFileCount={
                                                customer["P and L"] ?
                                                    customer["P and L"].length :
                                                    0}
                                        />
                                        <DocumentUpload key={5} customerid={customer.id}
                                            airtableField={"Original 941 Returns"}
                                            storageDirectory={"PayrollJournals"}
                                            label={"Original 941 Returns"}
                                            setProgress={setProgress}
                                            countName={"original941Returns"}
                                            fileCounts={fileCounts}
                                            setFileCounts={setFileCounts}
                                            initFileCount={
                                                customer["Original 941 Returns"] ?
                                                    customer["Original 941 Returns"].length :
                                                    0}
                                        />
                                        <DocumentUpload key={6} customerid={customer.id}
                                            airtableField={"Other Supporting Documentation"}
                                            storageDirectory={"OtherDocumentation"}
                                            label={"Other Documentation"}
                                            setProgress={setProgress}
                                            countName={"otherDocumentation"}
                                            fileCounts={fileCounts}
                                            setFileCounts={setFileCounts}
                                            initFileCount={
                                                customer["Other Supporting Documentation"] ?
                                                    customer["Other Supporting Documentation"].length :
                                                    0}
                                        />
                                    </DocumentationArea>
                                </CardContent>
                                <CardActions sx={{ marginLeft: "20px" }}>

                                </CardActions>
                            </Card>
                        </Paper>
                    }
                </TopSection>
            }
        </>
    );
};

export default AdminPortal;